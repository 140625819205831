import { cva } from 'class-variance-authority'
import { type ReactNode } from 'react'
import { cn } from '#app/utils/misc.js'
import { Icon } from './ui/icon'

const variants = cva('p-4 text-sm rounded-lg flex gap-2 w-full flex', {
	variants: {
		variant: {
			info: 'bg-blue-200 text-blue-700 dark:bg-gray-800 dark:text-blue-400',
			error: 'bg-red-50 text-red-800 dark:bg-gray-800 dark:text-red-400',
			success:
				'text-green-800 bg-green-50 dark:bg-gray-800 dark:text-green-400',
			warn: 'text-yellow-800 bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300',
		},
	},
	defaultVariants: {
		variant: 'info',
	},
})

export function Alert({
	children,
	variant,
	className,
}: {
	children: ReactNode
	variant?: 'info' | 'success' | 'error' | 'warn'
	className?: string
}) {
	return (
		<div className={cn(variants({ variant, className }))} role="alert">
			<Icon className="min-w-[20px]" name={variant ?? 'info'} />
			<div className="flex-1">{children}</div>
		</div>
	)
}
